<template>
    <div class="map">
        <div class="map-head">
             <span class="iconfont icon-fanhuijiantou" @click="goHistotry()"></span>
        </div>
        <div id="Map"></div>
        <div class="map-address">
            <div>
                <img src="../assets/images/u327.svg" />
            </div>
            <h5>上海市闵行区蓝湾科创中心</h5>
        </div>
        <div class="map-img">
            <img src="../assets/images/u1329.png" />
        </div>
    </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
    name: 'Map',
    data() {
        return {
            map: ''
        }
    },
    mounted() {
        this.initMap()
    },
    methods: {
        goHistotry() {
            this.$router.go(-1);
        },
        initMap() {
           AMapLoader.load({
               key: '75da4f8ae57022eaec10ce8d5d7f7004',
           version: "1.4.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
           resizeEnable: true,
           plugins: [
                "AMap.ToolBar", //工具条
                "AMap.Scale", // 比例尺
                "AMap.Geolocation", //定位
            ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
           }).then((AMap) => {
               this.map = new AMap.Map("Map", {
                   viewMode: "3D", //是否为3D地图模式
                   zoom: 18,//初始化地图级别
                   center: [121.386719,31.086869] //初始化地图中心点位置
               })
           }).catch(e=>{
               console.log(e)
           })
        }
    },
}
</script>

<style lang="less" scoped>
#Map {
    width:100%; 
    height: 200px;
}  
.map-head{
    height: 44px;
    line-height: 44px;
    background: rgba(0,129,255);
}
.map-head .icon-fanhuijiantou {
    font-size: 20px;
    color: #fff;
    padding-left: 10px;
}
.map-address {
    display: flex;
    flex-direction: row;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;

}
.map-address div {
   padding-left: 10px; 
}
.map-address div img {
    vertical-align: middle;
}
.map-address h5 {
    padding-left: 10px;
    font-size:12px;
}
.map-img img {
    width: 100%;
    height: 297px;
}
</style>